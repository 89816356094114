
import { defineComponent, ref } from "vue";
import { message } from "ant-design-vue";
import { FileItem } from "@/API/types";
import {uploadExtendInfoApi} from "@/API/claim/delaerClaim/delaer";

export default defineComponent({
  // components: {
  // },
  setup(props, ctx) {
    const isDone = ref(false);
    const prodId = ref("");
    const offerTypeId = ref("");
    const dealerCode = ref("");
    const offerTypeKey = ref("");
    const visible = ref(false);
    const tableHeight = window.innerHeight - 450;
    // 文件上传
    const invoiceUploadComplete = ref<boolean>(true);
    const formData = new FormData();
    const curUploadFile = ref<object>({});
    //文件大小
    const invoiceFileSize = ref<string>("");
    //进度条
    const invoicePercent = ref<number>(0);
    const invoiceProStatus = ref<string>("normal");
    //上传按钮
    const isSubmit = ref(true);

    //显示回传信息
    const dataFault = ref(false);

    //表格
    const columns = [
      {
        title: "车架号",
        dataIndex: "vinNo",
        key: "vinNo",
        width: 100,
      },
      {
        title: "提交结果",
        dataIndex: "message",
        key: "message",
        width: 100,
      },
    ];

    // const dataSource = [
    //   {
    //     vinNo: "vinNo",
    //     rtnMessage: "rtnMessage",
    //   },
    //   {
    //     vinNo: "vinNo",
    //     rtnMessage: "rtnMessage",
    //   },
    // ];

    interface Message {
      vinNo: string;
      message: string;
    }
    const dataSource = ref<Message[]>();

    const showDrawer = (item: any) => {
      prodId.value = item.prodId;
      offerTypeId.value = item.offerTypeId;
      dealerCode.value = item.dealerCode;
      offerTypeKey.value = item.offerTypeKey;
      visible.value = true;
    };

    //关闭抽屉事件
    const closeThisDrawer = () => {
      visible.value = false;
    };
    //关闭抽屉后事件
    const afterVisibleChange = () => {
      //aaa
      console.log("关闭");
      if (formData.has("file")) {
        formData.delete("file");
      }
      invoiceUploadComplete.value = true;
      isSubmit.value = true;
      invoicePercent.value = 0;
      invoiceProStatus.value = "normal";
      dataFault.value = false;
      isDone.value = false;
    };

    // 重写默认上传方法 获取合规的发票图片信息
    const invoiceCustomRequest = (source: any) => {
      if (formData.has("file")) {
        formData.delete("file");
      }
      formData.append("file", source.file);
      curUploadFile.value = source.file;
      invoiceFileSize.value =
        source.file.size / 1024 > 1024
          ? `${(source.file.size / 1024 / 1024).toFixed(1)} MB`
          : `${Math.floor(source.file.size / 1024)} KB`;
      invoiceUploadComplete.value = false;
    };

    // 上传前
    const beforeUpload = (file: FileItem) => {
      const isXlsx =
        file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      if (!isXlsx) {
        message.error("上传文件仅支持xlsx格式");
      }
      const isLt5M = file.size / 1024 / 1024 < 10;
      if (!isLt5M) {
        message.error("文件大小不能超过10M");
      }
      isSubmit.value = false;
      return isXlsx && isLt5M;
    };
    //上传文件
    const submitFile = () => {
      const params = {
        prodId: prodId.value,
        offerTypeId: offerTypeId.value,
        dealerCode:dealerCode.value,
        offerTypeKey:offerTypeKey.value,
        file: formData,
      };
      console.log(params);

      uploadExtendInfoApi(formData,params).then((res: any): void => {
        invoicePercent.value = 100;
        invoiceProStatus.value = "success";
        const data = res;
        isDone.value = true;
        if (data.code !== "0") {
          dataFault.value = true;
          dataSource.value = data.data;
        } else {
          message.success("上传成功");
          ctx.emit("close-drawer", {
            name: "isShowUploadInformation",
            value: false,
          });
        }
        ctx.emit("fresh-table");
      })

      // axios({
      //   method: "post",
      //   data: formData,
      //   url: "/claimapi/dealer/extend/uploadExtendInfo",
      //   params: params,
      // }).then((res) => {
      //   console.log(res);
      //   invoicePercent.value = 100;
      //   invoiceProStatus.value = "success";
      //   const data = res.data;
      //   if (data.code !== "0") {
      //     dataFault.value = true;
      //     dataSource.value = data.data;
      //   } else {
      //     message.success("上传成功");
      //     ctx.emit("close-drawer", {
      //       name: "isShowUploadInformation",
      //       value: false,
      //     });
      //   }
      //   ctx.emit("fresh-table");
      // });
    };
    
    const reUpload = ()=>{
      dataSource.value = [];
      dataFault.value = false;
      if (formData.has("file")) {
        formData.delete("file");
      }
      invoiceUploadComplete.value = true;
      isSubmit.value = true;
      invoicePercent.value = 0;
      invoiceProStatus.value = "normal";
      dataFault.value = false;
      isDone.value = false;
    }

    return {
      reUpload,
      showDrawer,
      tableHeight,
      dataFault,
      dataSource,
      columns,
      isSubmit,
      submitFile,
      invoiceFileSize,
      invoiceCustomRequest,
      curUploadFile,
      invoiceUploadComplete,
      closeThisDrawer,
      afterVisibleChange,
      beforeUpload,
      invoicePercent,
      invoiceProStatus,
      visible,
      isDone
    };
  },
});
