
import { defineComponent, ref ,onMounted  } from "vue";
import ExtendInformation  from './ExtendInformation/index.vue'
import {docReview,docSampleDealer,extendTitleList,getTablelist,deleteTableItem} from "@/API/claim/delaerClaim/delaer";
import supportExample from "../UploadSupport/UploadExample.vue"
import downloadFile from "@/utils/claim/downloadFile";
import UploadSupport from "./UploadSupport/index.vue"
import UploadInformation from "./ExtendInformation/UploadInformation.vue"
import { b64toBlob } from "@/utils/claim/b64toBlob";
import { message ,Modal} from "ant-design-vue";
export default defineComponent({
  components: {
      ExtendInformation,supportExample,UploadSupport,UploadInformation
  },
  setup(props) {

    //
    const option = ref<any>({});

    //上传文档抽屉页面
    const uploadSupport = ref<any>(null)

    //批量上传扩展信息
    const uploadInformation = ref<any>(null);

    //预览抽屉
    const supportExample = ref<any>(null);

    //查看支持文档的抽屉
    const visible = ref(false);
    //预览文件
    const base64code = ref("");
    const type = ref("");
    const pdfHeight = window.innerHeight - 250;

    //子dom
    const extendInformationDom = ref<any>();
    //表格高度
    const tableHeight = window.innerHeight - 380;

    //样例的照片
    const exampleImg = ref("@/assets/images/rv/upload.svg");

    //是否只是单纯的预览上传的照片
    const isOnlyView = ref(false);

    //文档样例
    const supportSample = ref<any[]>([]);

    //如果没有扩展信息，不能点击上传扩展信息按钮
    const isSupport = ref(false);

    const dataSource = ref<any[]>([]);

    const columns = ref<any[]>([])

    //刷新表格
    const freshTable = ()=>{
      console.log('刷新表格')
    }

    //关闭样例抽屉
    const closeDrawer=()=>{
      visible.value = false
    }

    //显示样例图片
    const showExample =(item: any)=>{
      console.log()
      supportExample.value.showDrawer(item);
    }
    //编辑table一列
    const editTable = (item: any)=>{
      const param = {
        groupId:option.value.programId,
        appId:item.id
      }

      // option.value ={
      //   progDesignId:props.groupId,
      //   incentiveCategory: props.incentiveCategory,
      //   offerTypeId: props.categoryID,
      //   offerTypeName: props.categoryName,
      //   programCode:  props.programeCode,
      //   programName:  props.programeName,
      //   dealerNameCn: "string",
      //   dealerCode: "string",
      // }
      extendInformationDom.value.showDrawer(param , 'edit' ,option.value);
    }

    //添加新的扩展信息
    const addExtendInformation = ()=>{
      const param = {
        groupId:option.value.programId,
        incentiveCategory: option.value.incentiveCategory,
        offerTypeId: option.value.offerTypeId,
        offerTypeName: option.value.categoryName,
        programCode:  option.value.programeCode,
        programName:  option.value.programeName,
      }

      // option.value ={
      //   progDesignId:props.groupId,
      //   incentiveCategory: props.incentiveCategory,
      //   offerTypeId: props.categoryID,
      //   offerTypeName: props.categoryName,
      //   programCode:  props.programeCode,
      //   programName:  props.programeName,
      //   dealerNameCn: "string",
      //   dealerCode: "string",
      // }

      extendInformationDom.value.showDrawer(param, 'view',option.value);
    }

    const getTableData=() =>{
      getTablelist({dealerCode:option.value.dealerCode, programId:option.value.programId,offerTypeId:option.value.offerTypeId}).then((res: any): void => {
        
        console.log('刷新表格')
        const p33= res;
        dataSource.value = [];
        for (let i=0;i<p33.length;i++){
          const one = {
            id:p33[i].id,
            supportDoc:p33[i].docList
          }
          p33[i].extendInfoLst.forEach((item: any)=>{
            one[item.fieldNameCn] = item.content;
          })

          dataSource.value.push(one)

        }
      })
    }
        //删除table一列
    const deleteTable = (item: any)=>{
      console.log(item)
      Modal.confirm({
        title: '请确认',
        content: '请确认是否要删除此条数据？',
        okText: '确认',
        onOk() {
          deleteTableItem({ appId:item.id}).then((res: any): void => {
            console.log(res)
            if (res === ""){
              message.success('删除数据成功')
              getTableData();
            }
          })
        },
        cancelText: '取消',
      });

    }

    //初始化view
    const init = (item: any)=>{

      option.value ={
        progDesignId:item.programId,
        programId:item.programId,
        incentiveCategory: item.incentiveCategory,
        offerTypeId: item.offerTypeId,
        offerTypeName: item.categoryName,
        programCode:  item.programeCode,
        programName:  item.programeName,
        dealerNameCn: item.dealerNameCn,
        dealerCode: item.dealerCode,
        eligiblePeriodTo:item.retailBegin,
        eligiblePeriodFrom:item.retailEnd
      }

      console.log(option.value)

      //进行初始化,进行异步访问
      
      const p1 = docSampleDealer({ programId:option.value.programId}); //获取文档样例
      const p2 = extendTitleList({ programId:option.value.programId}); //获取列表表头列信息
      const p3 = getTablelist({ programId:option.value.programId,offerTypeId:option.value.offerTypeId});//获取表格数据
      // cosnt p3 -

      Promise.all([p1,p2,p3]).then((result) => {
        // console.log(result)               //['成功了', 'success']
        // debugger
        if (result[0].length > 0 ){
          supportSample.value = result[0];
        }


        const supportArrayWidth = ref(0);

        //处理表格标题
        const p22 = result[1];
        columns.value = [];
        for (let i=0;i<p22.length;i++){
          columns.value.push({
            title: p22[i],
            dataIndex: p22[i],
            key: p22[i],
            width: p22[i].length * 20
          })
          supportArrayWidth.value = supportArrayWidth.value +p22[i].length * 8
        }
        columns.value.push({
          title: "支持文档",
          slots: { customRender: 'supportDoc' },
          key: "supportDoc",
          width:supportArrayWidth.value
        });
        columns.value.push({
          title: "操作",
          key: 'action',
          slots: { customRender: 'action' },
          width:100
        });
        // debugger

        if (result[2].length>0 && result[2][0].docList.length === 0){
          isSupport.value = true;
        }

        //处理列表数据
        const p33= result[2];
        for (let i=0;i<p33.length;i++){
          const one = {
            id:p33[i].id,
            supportDoc:p33[i].docList
          }

          if (p33[i].extendInfoLst === ""){
            continue
          }
          p33[i].extendInfoLst.forEach((item: any)=>{
            one[item.fieldNameCn] = item.content;
          })



          dataSource.value.push(one)

        }

        console.log(dataSource.value)


      }).catch((error) => {
        console.log(error)
      })


      // docSample({ programId:props.groupId}).then((res: any): void => {
      //   console.log(res)
      //   if (res.length > 0 ){
      //     supportSample.value = res;
      //   }
      // })

    }
    //预览支持文档
    const showSupport =(item: any,record: any)=>{
     if (item.filePath.length>0){
        // supportExample.value.showDrawerReview(item);

        docReview({ collectionDocId: item.collectionDocId }).then((res: any): void => {
        if (res.ext === "jpg"){
          base64code.value = "data:image/jpg;base64," + res.base64code;
          type.value = "jpg";
        } else if (res.ext === "pdf"){
           base64code.value = URL.createObjectURL(
            b64toBlob(res.base64code, "application/pdf")
          );
           type.value = "pdf";
        }
        

        visible.value = true;
      });
        // visible.value = true;
      }
    }

    //下载信息模板
    const dowloadDoc=()=>{

      const downloadParams = {
        progDesignId:option.value.programId
      };
      const params = {
        url: `/claimapi/dealer/doc/extendTemplateDownload`,
        method: "get",
        params: downloadParams,
      };
      downloadFile(params, "application/vnd-excel; char-set=UTF-8").then(
        (res) => {
          console.log(res);
        }
      );
    }

    //批量上传文档
    const uploadSupportDraw = ()=>{
      uploadSupport.value.showDrawer(option.value)
    }

    //扩展信息批量上传
    const showUploadDrawer = () => {
      uploadInformation.value.showDrawer(option.value);
    };

    //进行跳转
    const nextStep = ()=>{
      console.log('nextStep')
    }

    // onMounted(() => {
    //   //根据传入的id和其他参数，获取列表信息
    //   init();

    //   option.value ={
    //     progDesignId:props.groupId,
    //     incentiveCategory: props.incentiveCategory,
    //     offerTypeId: props.categoryID,
    //     offerTypeName: props.categoryName,
    //     programCode:  props.programeCode,
    //     programName:  props.programeName,
    //     dealerNameCn: "",
    //     dealerCode: "",
    //   }

    // });
    return {
      isSupport,
      showUploadDrawer,
      freshTable,
      uploadSupportDraw,
      uploadSupport,
      uploadInformation,
      dowloadDoc,
      base64code,
      type,
      pdfHeight,
      supportExample,
      supportSample,
      extendInformationDom,
      isOnlyView,
      visible,
      columns,
      exampleImg,
      dataSource,
      tableHeight,
      closeDrawer,
      editTable,
      deleteTable,
      addExtendInformation,
      showExample,
      init,
      showSupport,
      nextStep,
      getTableData,
    };
  },
});
