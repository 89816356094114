
import { defineComponent, ref } from "vue";
import { FileItem } from '@/API/types';
import { message ,Modal } from 'ant-design-vue';
import axios from "axios";
import { uploadProve } from "@/API/claim/delaerClaim/delaer";

export default defineComponent({
        emits: ["fresh-table-prove"],
      setup(props, ctx) {

        const option = ref<any>({});

        const visible = ref(false);
        const formData = new FormData();
          
        const isBeforeUpload = ref<boolean>(true)
        // 进度条 
        const isShowUploadBtn = ref<boolean>(true)
        const isClickAbled = ref<boolean>(true)

        const curUploadFile = ref<object>({});
        const curPercent = ref<number>(0)
        const uploadFileSize = ref<string>('')
        const completeTips = ref<boolean>(false)

        const showDrawer = (item: any)=>{
            option.value = item;
            visible.value = true;
        }

        // 上传前
        const beforeUpload = (file: FileItem) => {
            const isPdf = file.type === "application/pdf";
            if (!isPdf) {
                message.error('只能上传PDF文件！')
            }
            const isLt5M = file.size / 1024 / 1024 < 10
            if (!isLt5M) {
                message.error('文件大小不能超过10M')
            }
            return isPdf && isLt5M
        }

        const uploadRequest = (source: any) => {
            if (formData.has('document')){
                formData.delete('document')
            }
            formData.append('document', source.file);
            curUploadFile.value = source.file;
            uploadFileSize.value = source.file.size / 1024 > 1024 ? `${(source.file.size / 1024 / 1024).toFixed(1)} MB` : `${Math.floor(source.file.size / 1024)} KB`
            isBeforeUpload.value = false
        }

        const proveFile = ref<any>({});

        // 上传文件
        const uploadFile = (source: any) => {

            isClickAbled.value = false
            const params = {
                programId: option.value.programId,
                dealerCode:option.value.dealerCode,
                offerTypeId: option.value.offerTypeId,
                offerTypeKey:option.value.offerTypeKey,
                eligiblePeriodFrom:option.value.retailBegin,
                eligiblePeriodTo:option.value.retailEnd
                // programId: 'fe38fdec-2c3b-486b-84e4-b5e3d36045c7',
                // dealerCode:'code1',
                // offerTypeId: 'sssc',
                // offerTypeKey:'key1',
            }
            console.log(params)
            // axios({
            //     method: "post",
            //     data: formData,
            //     url: "/claimapi/salesIncentiveClaim/region/upload",
            //     params: params,
            //     // responseType: "blob",
            // }).then((res: any) => {
            //     console.log(res);
            // })
            uploadProve(formData,params).then((res: any) => {
                console.log(res);
                isShowUploadBtn.value = false
                curPercent.value = 100
                completeTips.value = true
                isClickAbled.value = true
                if (res.code === "0"){
                    proveFile.value = res.data;
                }
            })
        }

        //关闭抽屉后事件
        const afterVisibleChange = () => {
            curPercent.value = 0;
            uploadFileSize.value = "";
            completeTips.value = false;
            isClickAbled.value = true;
            isShowUploadBtn.value = true;
            curUploadFile.value = {};
            isBeforeUpload.value = true;
            if (formData.has('document')){
                formData.delete('document')
            }

            
            
        };
        //关闭抽屉事件
        const closeThisDrawer = () => {
            visible.value = false;
        };

        const handelBrowse = ()=>{
            curPercent.value = 0;
            uploadFileSize.value = "";
            completeTips.value = false;
            isClickAbled.value = true;
            isShowUploadBtn.value = true;
            curUploadFile.value = {};
            isBeforeUpload.value = true;
            if (formData.has('document')){
                formData.delete('document')
            }
        }

        const closeDrawer=()=>{
            
            ctx.emit('fresh-table-prove' ,proveFile.value)
            visible.value = false;
        }

        return {
            isBeforeUpload,beforeUpload,uploadRequest,isShowUploadBtn,isClickAbled,curUploadFile,curPercent,uploadFileSize,completeTips,
            visible,showDrawer,afterVisibleChange,uploadFile,closeThisDrawer,handelBrowse,closeDrawer
        }
      }
})
